























































































































































import { Component, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscTable from "@/components/Table.vue";
import UserInfo from "@/views/Communication/UserInfo.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import RegistrationInfo from "@/views/Communication/RegistrationInfo.vue";
import { namespace } from "vuex-class";
import RegistrationInfoModal from "@/views/Communication/RegistrationInfoModal.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import MailButton from "@/components/Button/MailButton.vue";
import { sendConfirmationEmail } from "@/store/Emails/actions";

const RegistrationsModule = namespace("registrations");
const RegistrationsFilterModule = namespace("registrations/localFilter");
const CommunicationModule = namespace("communication");
const EmailsModule = namespace("emails");

@Component({
  components: {
    RegistrationInfo,
    RemoveButton,
    CreateButton,
    UserInfo,
    FscTable,
    FscSimpleCard,
    FscPageHeader,
    RegistrationInfoModal,
    FscMultiselect,
    SaveButton,
    AbortButton,
    MailButton,
  },
})
export default class Registration extends mixins(ModalMixin) {
  public name = "Registration";

  @RegistrationsFilterModule.State("filter")
  public registrationsLocalFilter: any;

  @RegistrationsFilterModule.Mutation("SET_LOCAL_FILTER")
  public setRegistrationsLocalFilter!: (payload: Record<any, any>) => void;

  @RegistrationsModule.Action("findRegistrationsById")
  public findAllRegistrationsByDrivingSchool: any;

  @RegistrationsModule.Getter("getDataList")
  public allRegistrations: any;

  @RegistrationsModule.Action("setSelectedStudent")
  public setSelectedStudent: any;

  @RegistrationsModule.Action("studentMatchingRegistration")
  public findStudentMatchingRegistration: any;

  @RegistrationsModule.Getter("getDataItem")
  public isStudentMatched: any;

  @RegistrationsModule.Action("deleteSelectedStudent")
  public deleteSelectedStudentById: any;

  @RegistrationsModule.Action("fetchProcessStatuses")
  public processStatusAction: any;

  @RegistrationsModule.Action("updateProcessStatus")
  public updateStatusAction: any;

  @RegistrationsModule.Getter("getSuccess")
  public registrationStatusSuccess!: boolean;

  @RegistrationsModule.Getter("getIsLoading")
  public isLoading: any;

  @RegistrationsModule.Getter("getRegistrationProcessStatuses")
  public statuses: any;

  @RegistrationsModule.Action("deleteUnconfirmed")
  public deleteUnconfirmedById: any;

  @CommunicationModule.Action("notificationAsRead")
  public notificationAsRead!: (data: { registrationId: number; read: boolean }) => Promise<void>;

  @CommunicationModule.Action("notifications")
  public notifications!: () => void;

  @EmailsModule.Action("sendConfirmationEmail")
  public sendConfirmationEmail: any;

  @EmailsModule.Action("getSuccess")
  public sendConfirmationEmailSuccess: any;

  public selectedUserRow: any = null;
  public disabledActions = false;
  public selectedSubRegistration: any = null;
  public tbTrClass = "";

  public fields = [
    {
      key: "registrationType",
      label: "",
      sortable: true,
    },
    {
      key: "name",
      label: "",
      sortable: false,
    },
    {
      key: "email",
      label: this.$t("user.email"),
      sortable: false,
    },
    {
      key: "status",
      label: this.$t("general.status"),
      sortable: true,
    },
    {
      key: "registration",
      label: this.$t("general.registration"),
      sortable: false,
    },
    {
      key: "read",
      label: "",
      sortable: false,
    },
  ];

  public subFields = [
    {
      key: "registrationType",
      label: "",
      sortable: true,
      tdClass: "text-center",
    },
    {
      key: "name",
      label: "",
      sortable: false,
      tdClass: "text-center",
    },
    {
      key: "email",
      label: this.$t("user.email"),
      sortable: false,
      tdClass: "text-center",
    },
    {
      key: "registration",
      label: this.$t("general.registration"),
      sortable: false,
      tdClass: "text-center",
    },
    {
      key: "delete",
      label: "Delete",
      tdClass: "text-center",
    },
    {
      key: "read",
      label: "",
      sortable: false,
      tdClass: "text-center",
    },
  ];

  public selectedOnBoarding: any = "";

  public get onBoardingFilterOptions() {
    return [
      {
        id: 1,
        name: "Alle",
      },
      {
        id: 2,
        name: "Rückfragen",
        requestContract: false,
      },
      {
        id: 3,
        name: "Vertragsanfragen",
        requestContract: true,
      },
      {
        id: 4,
        name: "Unbestätigt",
      },
    ];
  }

  private cachedFilter() {
    if (this.registrationsLocalFilter instanceof Object) {
      this.selectedOnBoarding = this.registrationsLocalFilter.selected;
    } else {
      this.selectedOnBoarding = this.onBoardingFilterOptions[0];
    }
  }

  public async mounted(): Promise<void> {
    this.selectedUserRow = null;
    await this.fetchRegistrations();
    this.cachedFilter();
    this.onSelectedOnBoardingChange(this.selectedOnBoarding);
  }

  private async fetchRegistrations(): Promise<void> {
    await this.findAllRegistrationsByDrivingSchool();
    await this.processStatusAction();
  }

  public beforeDestroy(): void {
    this.setSelectedStudent(null);
  }

  public async onCreateStudent(): Promise<void> {
    const studentId = this.selectedUserRow ? this.selectedUserRow.studentId : null;
    if (this.selectedUserRow && this.selectedUserRow.confirmed && this.$route.name === "Students") {
      await this.$router.push({
        name: "StudentEdit",
        params: {
          id: studentId,
          registration: this.selectedUserRow,
        },
      });
    } else if (this.$route.name === "Registration") {
      await this.$router.push({ name: "StudentCreate", query: { onboarding: "true" } });
    } else {
      await this.$router.push({ name: "StudentCreate" });
    }
  }

  public async onDeleteStudent() {
    if (this.selectedUserRow && this.selectedUserRow.studentId && this.selectedUserRow.id === null) {
      this.hideModal("delete-modal-student");
      await this.deleteUnconfirmedById(this.selectedUserRow.studentId);

      if (this.registrationStatusSuccess) {
        await this.fetchRegistrations();
      }
    } else if (this.selectedUserRow && this.selectedUserRow.id) {
      this.hideModal("delete-modal-student");
      await this.deleteSelectedStudentById(this.selectedUserRow.id);

      if (this.registrationStatusSuccess) {
        await this.fetchRegistrations();
      }
    }
  }

  protected showDeleteSelectedStudentModal(): void {
    if (this.selectedUserRow) this.showModal("delete-modal-student");
  }

  protected hideDeleteSelectedStudentModal(): void {
    if (this.selectedUserRow) this.hideModal("delete-modal-student");
  }

  public async onRowClicked(ctx: any): Promise<void> {
    this.setSelectedStudent(null);
    this.selectedUserRow = null;

    if (!isEqual(this.selectedUserRow?.studentId, ctx?.studentId)) {
      this.setSelectedStudent(cloneDeep(ctx));
    }
    this.selectedUserRow = ctx;
    this.tbTrClass = "";
    if (this.selectedUserRow._showDetails) {
      this.selectedUserRow._showDetails = false;
    } else if (this.selectedUserRow.subRegistrations) {
      this.selectedUserRow._showDetails = true;
    }

    this.disabledActions = ctx.registrationType === "UNCONFIRMED";
    const { read, id } = ctx;
    if (!read && id) {
      await this.notificationAsRead({ read: true, registrationId: id });
      ctx.read = true;
    }
  }

  public async onSubRowClicked(ctx: any): Promise<void> {
    this.selectedUserRow = null;
    this.selectedUserRow = ctx;
    const { read, id } = ctx;
    if (!read && id) {
      await this.notificationAsRead({ read: true, registrationId: id });
      ctx.read = true;
    }
    this.tbTrClass = "bg-light";
  }

  public setCircleColor(row: any) {
    if (row.registrationType === "CONTRACT_REQUEST") {
      return `background-color: #793B86`;
    } else if (row.registrationType === "QUESTION") {
      return `background-color: #F9B342`;
    } else {
      return `background-color: #FF0000`;
    }
  }

  public setSubName(row: any) {
    if (row.registrationType === "CONTRACT_REQUEST") {
      return "Vertragsanfragen";
    } else if (row.registrationType === "QUESTION") {
      return "Rückfragen";
    } else {
      return "Unbestätigt";
    }
  }

  private filterAndMap(items: Array<any>, filterBy: (value: any) => boolean) {
    return items.reduce((previousValue: any, currentValue: any) => {
      if (filterBy(currentValue)) {
        previousValue.push({
          ...currentValue,
          _showDetails: false,
        });
      }
      return previousValue;
    }, []);
  }

  public get filteredOnBoarding() {
    switch (this.selectedOnBoarding?.id) {
      case 2:
        return this.filterAndMap(this.allRegistrations, (value: any) => {
          return value.registrationType === "QUESTION";
        });
      case 3:
        return this.filterAndMap(this.allRegistrations, (value: any) => {
          return value.registrationType === "CONTRACT_REQUEST";
        });
      case 4:
        return this.filterAndMap(this.allRegistrations, (value: any) => {
          return value.registrationType === "UNCONFIRMED";
        });
      default:
        return this.allRegistrations.map((registration: any) => ({
          ...registration,
          _showDetails: false,
        }));
    }
  }

  private onSelectedOnBoardingChange(selected: any): void {
    this.setRegistrationsLocalFilter({ selected });
  }

  public async onSelectStatus(status: any, item: any) {
    await this.updateStatusAction({
      resource: `registrations/update-status/${item.studentId}?registrationStatusId=${status.id}`,
    });
  }

  @Watch("success")
  public async onSuccess(success: any): Promise<any> {
    if (success) {
      this.selectedUserRow = null;
      this.selectedSubRegistration = null;
      await this.findAllRegistrationsByDrivingSchool();
      this.cachedFilter();
      this.notifications();
    }
  }

  private get removeButtonDisabled() {
    return Boolean(this.selectedUserRow?.id);
  }

  public async onSendMailConfirmation() {
    if (this.selectedUserRow?.studentId) {
      await this.sendConfirmationEmail(this.selectedUserRow?.email);
    }
    if (this.sendConfirmationEmailSuccess) {
      this.$toasted.success("Bestätigungs Email Verschickt!");
    }
  }

  public get canSendMailConfirmation() {
    return this.selectedUserRow?.registrationType !== "UNCONFIRMED";
  }
}

import { Component, Vue } from "vue-property-decorator";
import { BModal } from "bootstrap-vue/src/components/modal";

@Component
export default class ModalMixin extends Vue {
  public hideModal(modalId: string): void {
    this.$bvModal.hide(modalId);
  }

  public showModal(modalId: string): void {
    this.$bvModal.show(modalId);
  }
}

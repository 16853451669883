













import { Component, Prop, Vue } from "vue-property-decorator";
import Avatar from "@/components/Avatars/Avatar.vue";
import { formatStudentName } from "@/utils/NameUtil";
@Component({
  components: { Avatar },
})
export default class UserInfo extends Vue {
  public name = "UserInfo";

  @Prop({ type: Number })
  public userId!: number;

  @Prop()
  public educations!: any;

  @Prop({ type: String, default: () => "", required: true })
  public firstName!: string;

  @Prop({ type: String, default: () => "", required: true })
  public lastName!: string;

  protected get userEducations(): string {
    if (this.educations && this.educations.keyNumber) {
      return `${this.educations.licenseClass}${this.educations.keyNumber} `;
    } else if (this.educations && !this.educations.keyNumber) {
      return this.educations.licenseClass;
    }
    return "";
  }

  protected get fullName(): string {
    return formatStudentName(this.firstName, this.lastName);
  }
}
